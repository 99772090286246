<template>
  <div>
    <v-icon v-if="value" class="success--text">mdi-check-circle</v-icon>
    <v-icon v-else>mdi-circle-outline</v-icon>
  </div>
</template>

<script>
export default {
  name: 'AppCheckCircle',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style></style>
