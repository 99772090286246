<template>
  <v-card class="page-card-wrapper">
    <v-card-title class="pa-0 mb-3">{{ $t('text.filters') }}</v-card-title>
    <v-form ref="form">
      <v-row no-gutters>
        <template v-for="(item, index) in formFields">
          <v-col :key="index" cols="12" md="6" lg="3" class="pr-4 mt-3">
            <label class="text-capitalize">{{
              $t(`text.${item.label}`)
            }}</label>
            <AppFormField
              ref="formField"
              v-model="item.model"
              :outlined="true"
              :dense="true"
              :type="item.type"
              :items="item.items"
              :multiple="item.multiple"
              :rules="formFieldRules(item.key)"
              :clearable="true"
              :isOptional="true"
              class="mt-1"
            ></AppFormField>
          </v-col>
        </template>
        <v-col class="d-flex justify-end align-end pt-4 pr-4">
          <v-btn
            depressed
            outlined
            color="primary"
            class="text-caption"
            @click="actionReset"
            >{{ $t('text.reset') }}</v-btn
          >
          <v-btn
            depressed
            class="text-caption ml-2"
            color="primary"
            @click="actionSearch"
            >{{ $t('text.search') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'AppCardFilter',
  props: {
    formFields: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    rules: {}
  }),
  mounted() {
    this.mountFormFieldRules()
  },
  methods: {
    formFieldRules(key) {
      return this.rules[key] ? this.rules[key] : []
    },
    actionReset() {
      this.$refs.form.reset()
      this.$refs.formField.forEach((x) => {
        x.clearDate()
      })
      this.actionSearch()
    },
    actionSearch() {
      if (this.$refs.form.validate()) {
        this.$emit('app-card-filter-search')
      }
    },
    mountFormFieldRules() {
      this.formFields.forEach((x) => {
        x.rules ? (this.rules[x.key] = x.rules) : (this.rules[x.key] = [])
      })
    }
  }
}
</script>

<style></style>
