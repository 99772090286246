<template>
  <div
    class="app-table-description d-inline-block text-truncate"
    :style="`max-width: ${config.maxWidth} `"
  >
    <div v-if="config.isHtml" v-html="value" class="html-field"></div>
    <div v-else>
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTableDescription',
  props: {
    value: {
      type: String
    },
    config: {
      type: Object,
      default: () => {
        return {
          isHtml: false,
          maxWidth: '150px'
        }
      }
    }
  }
}
</script>

<style lang="scss">
.app-table-description {
  p {
    margin-bottom: 0 !important;
  }

  .html-field p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
</style>
