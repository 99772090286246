<template>
  <v-card class="page-card-wrapper mt-6">
    <v-row v-if="canCreate" justify="end">
      <v-col cols="auto">
        <v-btn
          depressed
          color="primary"
          class="text-caption"
          @click="newRecord"
          >{{ $t('text.newRecord') }}</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :no-data-text="$t('text.noDataAvailable')"
      mobile-breakpoint="1264"
      hide-default-footer
    >
      <template
        v-for="(i, index) in headers"
        v-slot:[`header.${i.value}`]="{ header }"
      >
        <span class="text-uppercase" :key="index">
          {{ $t(`text.${header.text}`) }}
        </span>
      </template>

      <template
        v-for="(header, index) in headers"
        v-slot:[`item.${header.value}`]="{ item, value }"
      >
        <div :key="index">
          <component
            v-if="header.layout"
            :is="header.layout"
            :item="item"
            :value="value"
            :header="header"
            :config="header.config"
            @app-table-action-edit="actionEdit"
            @app-table-action-delete="actionDelete"
          >
          </component>
          <div v-else>{{ value }}</div>
        </div>
      </template>
    </v-data-table>
    <AppPagination
      :pagination="pagination"
      @app-pagination-update="actionPagination"
    ></AppPagination>
  </v-card>
</template>

<script>
import AppPagination from '@/components/base/AppPagination.vue'
import AppCheckCircle from '@/components/base/AppCheckCircle.vue'
import AppTableActions from '@/components/base/AppTableActions.vue'
import AppTableDescription from '@/components/base/AppTableDescription.vue'
import { ACCESS_RIGHTS_SERVICE } from '@/services'

export default {
  name: 'AppSmartTable',
  components: {
    AppPagination,
    AppCheckCircle,
    AppTableActions,
    AppTableDescription
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: function () {
        return {
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        }
      }
    },
    canCreate: {
      type: Boolean,
      default: false
    },
    newRecordPage: {
      type: String,
      default: ''
    },
    newRecordButtonPermissions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    accessRightsService: ACCESS_RIGHTS_SERVICE
  }),
  methods: {
    newRecord() {
      this.$router.push({ name: this.newRecordPage })
    },
    actionPagination(data) {
      this.$emit('app-smart-table-pagination-update', data)
    },
    actionEdit(data) {
      this.$emit('app-smart-table-edit', data)
    },
    actionDelete(data) {
      this.$emit('app-smart-table-delete', data)
    }
  }
}
</script>

<style></style>
