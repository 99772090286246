<template>
  <div>
    <v-icon
      v-if="header.actions.includes('edit')"
      class="mx-2"
      @click="actionEdit(item)"
    >
      mdi-file-document-edit-outline
    </v-icon>
    <v-icon
      v-if="header.actions.includes('delete')"
      class="mx-2"
      @click="actionDelete(item.id)"
    >
      mdi-delete-outline
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'AppTableActions',
  props: {
    header: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    actionEdit(data) {
      this.$emit('app-table-action-edit', data)
    },
    actionDelete(data) {
      this.$emit('app-table-action-delete', data)
    }
  }
}
</script>

<style></style>
