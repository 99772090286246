<template>
  <div>
    <AppCardFilter
      v-if="filters > 1"
      :formFields="filters"
      @app-card-filter-search="searchList"
    ></AppCardFilter>
    <AppTable
      :headers="headers"
      :items="items"
      :pagination="pagination"
      :canCreate="canCreate"
      :newRecordPage="newRecordPage"
      :newRecordButtonPermissions="newRecordButtonPermissions"
      @app-smart-table-edit="actionEdit"
      @app-smart-table-delete="requestDelete"
      @app-smart-table-pagination-update="searchList"
    ></AppTable>
  </div>
</template>

<script>
import AppTable from '@/components/base/AppTable.vue'
import AppCardFilter from '@/components/base/AppCardFilter.vue'
import { SHARED } from '@/constants'
import commonMixin from '@/mixins/common.mixin'
import crudMixin from '@/mixins/crud.mixin'

export default {
  name: 'AppSmartList',
  components: {
    AppTable,
    AppCardFilter
  },
  mixins: [commonMixin, crudMixin],
  props: {
    filters: {
      type: Array,
      default: () => [],
      required: false
    },
    headers: {
      type: Array,
      default: () => [],
      required: true
    },
    actions: {
      type: Object,
      default: () => {},
      required: true
    },
    canCreate: {
      type: Boolean,
      default: false
    },
    newRecordPage: {
      type: String,
      default: ''
    },
    editRecordPage: {
      type: String,
      default: ''
    },
    newRecordButtonPermissions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    items: [],
    pagination: {
      currentPage: 1,
      total: 1,
      perPage: SHARED.DEFAULT_PAGE_SIZE_ITEMS[0],
      lastPage: 1
    }
  }),
  computed: {
    getListComplete() {
      let obj = this.actions.getList
      return this.$store.state[obj.module][obj.state].complete
    },
    deleteRecordComplete() {
      let obj = this.actions.deleteRecord
      if (obj != undefined) {
        return this.$store.state[obj.module][obj.state].complete
      } else {
        return false
      }
    },
    filtersData() {
      let filter = this.filters
      let obj = {}

      filter.forEach((x) => {
        obj[x.key] = x.model
      })

      obj.perPage = SHARED.DEFAULT_PAGE_SIZE_ITEMS[0]
      obj.page = 1

      return obj
    }
  },
  watch: {
    getListComplete() {
      let obj = this.actions.getList
      let response = this.$store.state[obj.module][obj.state]
      if (response.complete) {
        this.getListCompleteAction(response)
      }
    },
    deleteRecordComplete() {
      let obj = this.actions.deleteRecord
      let response = this.$store.state[obj.module][obj.state]
      let successAction = () => {
        this.getList(this.filtersData)
      }
      if (response) {
        if (response.complete) {
          this.deleteRecordCompleteAction(response, successAction)
        }
      }
    }
  },
  created() {
    this.initAppSmartList()
  },
  methods: {
    getListCompleteAction(response) {
      let title = this.actions.getList.title

      if (response.code == 0) {
        this.items = response.data
        this.pagination = response.pagination
      } else {
        this.openErrorAppDialogInfo(title, response)
      }

      this.initialGetListState()
    },
    searchList(pagination) {
      let data = this.filtersData

      if (pagination) {
        data.perPage = pagination.size
        data.page = pagination.page
      }

      this.getList(data)
    },
    initialGetListState() {
      this.$store.dispatch(this.actions.initialGetListState)
    },
    getList(data) {
      this.$store.dispatch(this.actions.getList.action, { data })
    },
    initAppSmartList() {
      this.getList(this.filtersData)
    },
    actionEdit(data) {
      let page = this.editRecordPage
      page
        ? this.$router.push({
            name: this.editRecordPage,
            params: { id: data.id }
          })
        : this.$emit('app-smart-list-edit', data)
    },
    actionSearch() {
      this.$emit('app-smart-list-search')
    },
    actionPagination(data) {
      this.$emit('app-smart-list-pagination-update', data)
    }
  }
}
</script>

<style></style>
