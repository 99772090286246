<template>
  <div>
    <v-divider></v-divider>
    <v-card class="elevation-0 mt-4 d-block d-sm-flex justify-end align-center">
      <div class="d-flex justify-center align-center">
        <label class="text-capitalize text-caption">{{
          $t('text.rowsPerPage')
        }}</label>
        <div class="ml-4 app-pagination-select-box">
          <v-select
            dense
            hide-details
            :items="pageSizeItems"
            v-model="pageSizeValue"
            @input="change"
          ></v-select>
        </div>
      </div>
      <div class="my-2 my-sm-0 mx-6 text-center">
        {{ `${pagination.currentPage} - ${pagination.lastPage}` }}
      </div>
      <div class="d-flex justify-center align-center">
        <v-btn
          icon
          :disabled="pagination.currentPage == 1"
          @click="toFirstPage"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="pagination.currentPage == 1"
          @click="toPreviousPage"
        >
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="pagination.currentPage == pagination.lastPage"
          @click="toNextPage"
        >
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="pagination.currentPage == pagination.lastPage"
          @click="toLastPage"
        >
          <v-icon> mdi-arrow-right </v-icon>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { SHARED } from '@/constants'

export default {
  name: 'AppPagination',
  props: {
    pagination: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    pageSizeItems: SHARED.DEFAULT_PAGE_SIZE_ITEMS,
    pageSizeValue: SHARED.DEFAULT_PAGE_SIZE_ITEMS[0]
  }),
  methods: {
    toFirstPage() {
      let value = {
        page: 1,
        size: this.pageSizeValue
      }
      this.updatePagination(value)
    },
    toLastPage() {
      let value = {
        page: this.pagination.lastPage,
        size: this.pageSizeValue
      }
      this.updatePagination(value)
    },
    toPreviousPage() {
      let value = {
        page: this.pagination.currentPage - 1,
        size: this.pageSizeValue
      }
      this.updatePagination(value)
    },
    toNextPage() {
      let value = {
        page: this.pagination.currentPage + 1,
        size: this.pageSizeValue
      }
      this.updatePagination(value)
    },
    change() {
      let value = {
        page: 1,
        size: this.pageSizeValue
      }
      this.updatePagination(value)
    },
    updatePagination(value) {
      this.$emit('app-pagination-update', value)
    }
  }
}
</script>

<style lang="scss">
.app-pagination-select-box {
  max-width: 60px;
}
</style>
